import React, { useContext } from "react";
import { asset } from "../../assets/assets";
import { TaskContext } from "../../context/TaskContext";

function SearchBar() {
  const { searchQueries, setSearchQueries } = useContext(TaskContext);
  return (
    <div className="relative">
      <input
        className="pl-14 py-2.5 w-full rounded-full outline-none mb-4 md:mb-0 lg:mb-4 xl:mb-0"
        type="text"
        value={searchQueries}
        onChange={(e) => setSearchQueries(e.target.value)}
        placeholder="Search"
      />
      <span className="absolute top-2.5 left-4">
        <img className="w-6" src={asset.searchIcon} alt="" />
      </span>
    </div>
  );
}

export default SearchBar;
