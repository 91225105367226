import React, { useContext } from "react";
import { FilterBarContext } from "../../context/FilterBarContext";
import { filterList } from "../../constant/constant";

function ProgressFilterBar() {
  const { progressFilter, setProgressFilter } = useContext(FilterBarContext);
  return (
    <div
      className="w-full md:w-fit overflow-y-auto flex gap-4"
      style={{ scrollbarWidth: "none" }}
    >
      {filterList.map((item) => {
        return (
          <ProgressFilterItem
            key={item}
            item={item}
            progressFilter={progressFilter}
            setProgressFilter={setProgressFilter}
          />
        );
      })}
    </div>
  );
}

const ProgressFilterItem = ({ progressFilter, setProgressFilter, item }) => {
  return (
    <div
      className={`${
        progressFilter === item
          ? "bg-ctaButton text-white hover:bg-ctaButtonHover"
          : "bg-white text-ctaRed hover:bg-progressBarWhiteHover"
      }  py-2 px-4 lg:py-3 lg:px-6 rounded-full whitespace-nowrap cursor-pointer`}
      onClick={() => setProgressFilter(item)}
    >
      <p className="text-smNormal">{item}</p>
    </div>
  );
};

export default ProgressFilterBar;
