import { asset } from "../assets/assets";


export const listItem = [
    { icon: asset.allIcon, name: "all" },
    { icon: asset.workIcon, name: "work" },
    { icon: asset.personalIcon, name: "personal" },
    { icon: asset.familyIcon, name: "family" },
];

export const filterList = ["All", "In Progress", "Completed", "Archieved"];


export const listDropdown = [
    { value: "work", name: "work" },
    { value: "personal", name: "personal" },
    { value: "family", name: "family" },
];

export const priorityDropdown = [
    { value: "high", name: "high" },
    { value: "medium", name: "medium" },
    { value: "low", name: "low" },
];