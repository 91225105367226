import addIcon from "./icons/add.svg";
import allIcon from "./icons/all.svg";
import arrowDownIcon from "./icons/arrow-down.svg";
import deleteTaskIcon from "./icons/delete-task.svg";
import deleteIcon from "./icons/delete.svg";
import editIcon from "./icons/edit.svg";
import familyIcon from "./icons/family.svg";
import flagIcon from "./icons/flag.svg";
import homeIcon from "./icons/home.svg";
import listIcon from "./icons/list.svg";
import personalIcon from "./icons/personal.svg";
import searchIcon from "./icons/search.svg";
import workIcon from "./icons/work.svg";
import mobileMenuIcon from "./icons/menu.svg";
import closeIcon from "./icons/close.svg";
import checkedIcon from "./icons/checked.svg";
import restoreIcon from "./icons/restore.svg";

export const asset = {
  addIcon,
  allIcon,
  arrowDownIcon,
  deleteTaskIcon,
  deleteIcon,
  editIcon,
  familyIcon,
  flagIcon,
  homeIcon,
  listIcon,
  personalIcon,
  searchIcon,
  workIcon,
  mobileMenuIcon,
  closeIcon,
  checkedIcon,
  restoreIcon,
};
