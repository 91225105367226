import React, { useState, createContext, useEffect } from "react";

export const MobileViewContext = createContext(null);

const MobileViewContextProvider = ({ children }) => {
  const [isMobileView, setIsMobileView] = useState(true);
  const [isDesktopView, setIsDesktopView] = useState(true);
  const [showNavPanel, setShowNavPanel] = useState(false);

  useEffect(() => {
    const handleSize = () => {
      const newIsMobileView = window.innerWidth < 768;
      const newIsDesktopView = window.innerWidth < 1024;
      const newShowNavBar = window.innerWidth >= 1024;
      setIsMobileView(newIsMobileView);
      setIsDesktopView(newIsDesktopView);
      setShowNavPanel(newShowNavBar);
    };

    handleSize();
    window.addEventListener("resize", handleSize);

    return () => window.removeEventListener("resize", handleSize);
  }, []);

  const value = {
    showNavPanel,
    isMobileView,
    isDesktopView,
    setShowNavPanel,
  };

  return (
    <MobileViewContext.Provider value={value}>
      {children}
    </MobileViewContext.Provider>
  );
};

export default MobileViewContextProvider;
