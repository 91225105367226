import Home from "./pages/Home";
import MobileViewContextProvider from "./context/MobileViewContext";
import ModalContextProvider from "./context/ModalContext";

function App() {
  return (
    <MobileViewContextProvider>
      <div className="font-inter">
        <ModalContextProvider>
          <Home />
        </ModalContextProvider>
      </div>
    </MobileViewContextProvider>
  );
}

export default App;
