import React, { useContext } from "react";
import { MobileViewContext } from "../../context/MobileViewContext";
import { ModalContext } from "../../context/ModalContext";
import { TaskContext } from "../../context/TaskContext";

function GeneralModal() {
  const { isMobileView } = useContext(MobileViewContext);
  const { setShowGeneralModal, generalModalType } = useContext(ModalContext);
  return (
    <div
      className={`${
        !isMobileView &&
        "bg-modalBackgroundOverlay flex justify-center items-center"
      } absolute top-0 left-0 z-30 h-screen w-screen `}
      onClick={() => setShowGeneralModal(false)}
    >
      <div className="h-full w-full md:h-[250px] md:w-[600px] md:rounded-[15px] bg-white p-6 flex flex-col justify-between relative">
        <div>
          <h1 className="font-bold text-smHeader">
            {generalModalType === "archieved"
              ? "Archieve Task?"
              : "Delete Task?"}
          </h1>
          <p>
            {generalModalType === "archieved"
              ? "The task can still be found and restore in Archieved tab."
              : "The task will be permanently deleted and can't be found. Are you sure?"}
          </p>
        </div>
        <CtaButton />
      </div>
    </div>
  );
}

const CtaButton = () => {
  const { generalModalType, setShowGeneralModal } = useContext(ModalContext);
  const { task, setTask, selectedTask } = useContext(TaskContext);

  const today = new Date();
  const todayFormattedDate = today.toISOString().split("T")[0];
  const archiveTask = () => {
    const archiveStatus = task.map((item) => {
      if (item.id === selectedTask[0].id) {
        return {
          ...item,
          status: "archieved",
          updatedDate: todayFormattedDate,
        };
      }
      return item;
    });
    setTask(archiveStatus);
  };

  const deleteTask = () => {
    const deleteTask = task.filter((item) => item.id !== selectedTask[0].id);
    setTask(deleteTask);
  };

  return (
    <div className="pt-4 border-t-2 border-divider flex justify-end gap-4">
      <button
        className="px-4 py-2 border-ctaBorder hover:border-ctaBorderHover hover:bg-progressBarWhiteHover border-2 text-smNormal rounded-[10px]"
        onClick={() => setShowGeneralModal(false)}
      >
        Cancel
      </button>
      <button
        className="px-4 py-2 text-smNormal bg-ctaButton text-white border-ctaBorder hover:bg-ctaButtonHover hover:border-ctaBorderHover border-2 rounded-[10px]"
        onClick={generalModalType === "archieved" ? archiveTask : deleteTask}
      >
        Yes
      </button>
    </div>
  );
};

export default GeneralModal;
