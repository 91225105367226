import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

function CtaButton({ icon, name }) {
  const { handleModalPopUp } = useContext(ModalContext);

  return (
    <div>
      <button
        className="fixed bottom-5 right-5 border-none bg-ctaButton text-white hover:bg-ctaButtonHover py-3 pl-3 pr-5 rounded-[15px] flex gap-2"
        onClick={() => handleModalPopUp("Create")}
      >
        <img src={icon} className="w-6" alt="" />
        <p className="text-smNormal md:text-mdNormal">{name}</p>
      </button>
    </div>
  );
}

export default CtaButton;
