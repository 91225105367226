import React, { useContext } from "react";
import { TaskContext } from "../context/TaskContext";
import { asset } from "../assets/assets";
import { ModalContext } from "../context/ModalContext";
import { FilterBarContext } from "../context/FilterBarContext";

function ToDoCard() {
  const { task, searchQueries } = useContext(TaskContext);
  const { progressFilter, listFilter } = useContext(FilterBarContext);
  const filteredTask = task.filter((item) => {
    return (
      (searchQueries === "" ||
        item.title.toLowerCase().includes(searchQueries.toLowerCase())) &&
      (progressFilter === "All" ||
        item.status.toLowerCase().includes(progressFilter.toLowerCase())) &&
      (listFilter === "all" ||
        item.listAssigned.toLowerCase().includes(listFilter.toLowerCase()))
    );
  });

  const sortedTasks = filteredTask.sort((a, b) => {
    const customOrder = {
      completed: 1,
      archieved: 2,
    };
    const getStatusPriority = (status) => {
      return customOrder[status] || 0;
    };

    const statusComparison =
      getStatusPriority(a.status) - getStatusPriority(b.status);

    if (statusComparison === 0) {
      if (a.updatedDate && b.updatedDate) {
        return new Date(b.updatedDate) - new Date(a.updatedDate);
      }
    }
    return statusComparison;
  });

  return (
    <div className="mt-4 grid grid-cols-1 480px:grid-cols-2 700px:grid-cols-3 950px:grid-cols-4 lg:grid-cols-2 1150px:grid-cols-3 1350px:grid-cols-4 gap-4">
      {task.length === 0 ? (
        <p className="col-span-4 text-center mt-[10vh]">
          <span className="font-bold">Hmmm... nothing popped up</span>
          <br />
          Start creating your first task now~
        </p>
      ) : (
        filteredTask.length === 0 && (
          <p className="col-span-4 text-center mt-[10vh]">
            <span className="font-bold">
              Looks like your search is feeling a bit lonely. <br />
            </span>
            Please refine your search terms / filter(s)
          </p>
        )
      )}

      {filteredTask.map((item) => {
        return (
          <Card
            key={item.id}
            id={item.id}
            title={item.title}
            startTime={item.startTime}
            endTime={item.endTime}
            taskToDo={item.taskToDo}
            priority={item.priority}
            listAssigned={item.listAssigned}
            status={item.status}
            createdDate={item.createdDate}
            updatedDate={item.updatedDate}
          />
        );
      })}
    </div>
  );
}

const Card = ({
  id,
  title,
  startTime,
  endTime,
  taskToDo,
  priority,
  listAssigned,
  status,
  createdDate,
  updatedDate,
}) => {
  const taskTotal = taskToDo.length;
  const taskCompleted = taskToDo.filter((item) => item.completed === true);
  const numOfCompletedTask = taskCompleted.length;
  const completedRate = Math.round((numOfCompletedTask / taskTotal) * 100);
  return (
    <div className="bg-white w-full h-full p-4 rounded-[15px] flex flex-col justify-between">
      <div>
        <LabelAndEndTime
          priority={priority}
          status={status}
          endTime={endTime}
        />
        <h3 className="font-bold text-smHeader mb-1 md:mb-2 leading-none">
          {title}
        </h3>
      </div>
      <div>
        <StartEndTime startTime={startTime} endTime={endTime} />
        <Progress completedRate={completedRate} />
        <CtaButton
          id={id}
          taskTotal={taskTotal}
          numOfCompletedTask={numOfCompletedTask}
          status={status}
        />
        <CreateAndUpdatedTime
          createdDate={createdDate}
          updatedDate={updatedDate}
          status={status}
        />
      </div>
      <ListLabel listAssigned={listAssigned} />
    </div>
  );
};

const LabelAndEndTime = ({ priority, status, endTime }) => {
  const remainingTime = new Date(endTime) - new Date();

  const millisecondsInAnHour = 1000 * 60 * 60;
  const millisecondsInADay = millisecondsInAnHour * 24;
  const days = Math.floor(remainingTime / millisecondsInADay);
  const hours = Math.floor(
    (remainingTime % millisecondsInADay) / millisecondsInAnHour
  );

  return (
    <div className="flex justify-between items-center mb-1 md:mb-2">
      {status === "in progress" ? (
        <div
          className={`${
            priority === "high"
              ? "bg-highPriorityTag"
              : priority === "medium"
              ? "bg-mediumPriorityTag"
              : "bg-lowPriorityTag"
          } text-smSmall py-1 px-2 rounded-full capitalize`}
        >
          {priority}
        </div>
      ) : (
        <div
          className={`${
            status === "completed" ? "bg-yellow-200" : "bg-gray-800 text-white"
          } text-smSmall py-1 px-2 rounded-full capitalize`}
        >
          {status}
        </div>
      )}

      {status === "in progress" && (
        <p className="text-smSmall text-grayFont">
          <span>{remainingTime > 0 ? "Ends in " : "Expire for "}</span>{" "}
          {days !== 0 && (days > 0 ? `${days} day(s)` : `${days * -1} day(s)`)}
          {days === 0 &&
            hours !== 0 &&
            (hours > 0 ? `${hours} hour(s)` : `${hours * -1} hour(s)`)}
        </p>
      )}
    </div>
  );
};

const ListLabel = ({ listAssigned }) => {
  const { setListFilter } = useContext(FilterBarContext);
  return (
    <div
      className="bg-inputBackground w-fit rounded-full text-smSmall py-1 px-2  mt-1 md:mt-2 capitalize flex items-center gap-1.5 cursor-pointer"
      onClick={() => setListFilter(listAssigned)}
    >
      <img className="w-4" src={asset[`${listAssigned}Icon`]} alt="" />
      <p>{listAssigned}</p>
    </div>
  );
};

const StartEndTime = ({ startTime, endTime }) => {
  return (
    <div className="flex items-center gap-1 mb-1 md:mb-2">
      <img className="w-6 cursor-pointer" src={asset.flagIcon} alt="" />
      <p className="text-[12px]">
        {startTime} - {endTime}
      </p>
    </div>
  );
};

const Progress = ({ completedRate }) => {
  return (
    <div className="mb-2">
      <div className="flex justify-between items-center text-smSmall text-grayFont mb-0.5">
        <p>Progress</p>
        <p>{completedRate}%</p>
      </div>

      <div className="relative w-full h-1 bg-customBgColor rounded-full">
        <div
          className="absolute top-0 left-0 h-1 bg-green-300 rounded-full"
          style={{ width: `${completedRate}%` }}
        ></div>
      </div>
    </div>
  );
};

const CtaButton = ({ id, taskTotal, numOfCompletedTask, status }) => {
  const { handleModalPopUp, handleGeneralModal } = useContext(ModalContext);
  const { selectedTask, getSelectedTask, setTask, task } =
    useContext(TaskContext);

  const clickEditButton = () => {
    handleModalPopUp("Edit");
    getSelectedTask(id);
  };

  const clickDeleteButton = () => {
    handleGeneralModal(status === "archieved" ? "delete" : "archieved");
    getSelectedTask(id);
  };

  const clickRestoreButton = () => {
    console.log("restore");
    getSelectedTask(id);
    const restoredTask = task.map((item) => {
      if (item.id === selectedTask[0].id) {
        return { ...item, status: "in progress" };
      }
      return item;
    });
    setTask(restoredTask);
  };

  return (
    <div className="flex justify-between items-center mb-2">
      <div className="flex gap-1 items-center">
        <img className="w-6" src={asset.listIcon} alt="" />
        <p className="text-smSmall text-grayFont">
          {numOfCompletedTask}/{taskTotal}
        </p>
      </div>

      <div className="flex items-center gap-1.5">
        {status !== "archieved" && (
          <img
            onClick={clickEditButton}
            className="w-6 cursor-pointer"
            src={asset.editIcon}
            alt=""
          />
        )}
        {status === "archieved" && (
          <img
            className="w-6 cursor-pointer"
            src={asset.restoreIcon}
            alt=""
            onClick={clickRestoreButton}
          />
        )}
        <img
          className="w-6 cursor-pointer"
          src={asset.deleteIcon}
          alt=""
          onClick={clickDeleteButton}
        />
      </div>
    </div>
  );
};

const CreateAndUpdatedTime = ({ createdDate, updatedDate, status }) => {
  return (
    <div>
      <p className="text-smSmall text-grayFont leading-tight">
        Created on: {createdDate}
      </p>

      {updatedDate !== "-" && (
        <p className="text-smSmall text-grayFont leading-tight">
          {status === "archieved"
            ? "Archieved on"
            : status === "completed"
            ? "Completed on"
            : "Last updated"}
          {`: ${updatedDate}`}
        </p>
      )}
    </div>
  );
};

export default ToDoCard;
