import React, { createContext, useState } from "react";

export const FilterBarContext = createContext(null);

const FilterBarContextProvider = ({ children }) => {
  const [progressFilter, setProgressFilter] = useState("All");
  const [listFilter, setListFilter] = useState("all");

  const value = {
    progressFilter,
    setProgressFilter,
    listFilter,
    setListFilter,
  };

  return (
    <FilterBarContext.Provider value={value}>
      {children}
    </FilterBarContext.Provider>
  );
};

export default FilterBarContextProvider;
