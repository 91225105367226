import React, { useEffect, useState } from "react";

function DateTime() {
  const [date, setDate] = useState(
    new Intl.DateTimeFormat("en-GB", {
      dateStyle: "full",
    }).format(new Date())
  );
  useEffect(() => {
    const interval = setInterval(() => {
      const newDate = new Date();
      const formattedDate = new Intl.DateTimeFormat("en-GB", {
        dateStyle: "full",
      }).format(newDate);
      setDate(formattedDate);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className="lg:mt-[30px] lg:mb-4">
      <p className="text-smNormal md:text-mdNormal lg:text-mdHeader font-semibold lg:font-bold">
        {date}
      </p>
    </div>
  );
}

export default DateTime;
