import React, { useContext } from "react";
import NavigationBar from "../components/NavBar/NavigationPanel";
import TaskContextProvider from "../context/TaskContext";
import MobileNavBar from "../components/NavBar/MobileNavBar";
import { MobileViewContext } from "../context/MobileViewContext";
import DateTime from "../components/General/DateTime";
import SearchBar from "../components/SearchAndFilter/SearchBar";
import ProgressFilterBar from "../components/SearchAndFilter/ProgressFilterBar";
import CtaButton from "../components/General/CtaButton";
import { asset } from "../assets/assets";
import CreateEditTaskModal from "../components/Modal/CreateEditTaskModal";
import { ModalContext } from "../context/ModalContext";
import ToDoCard from "../components/ToDoCard";
import FilterBarContextProvider from "../context/FilterBarContext";
import GeneralModal from "../components/Modal/GeneralModal";

function Home() {
  const { isDesktopView } = useContext(MobileViewContext);
  const { showGeneralModal, showCreateEditModal } = useContext(ModalContext);

  return (
    <TaskContextProvider>
      <FilterBarContextProvider>
        <div className="w-screen h-full">
          <div className="max-w-[1440px] h-full mx-auto p-4 lg:p-6 flex flex-col lg:flex-row gap-4">
            {isDesktopView ? <MobileNavBar /> : <NavigationBar />}
            <div className="lg:flex-1 w-full">
              {!isDesktopView && <DateTime />}
              <div className="md:flex md:items-center md:gap-4 lg:block xl:flex">
                <div className="flex-1">
                  <SearchBar />
                </div>
                <ProgressFilterBar />
              </div>
              <ToDoCard />
            </div>
            <div>
              <CtaButton icon={asset.addIcon} name={"Add Task"} />
              {showCreateEditModal && <CreateEditTaskModal />}
              {showGeneralModal && <GeneralModal />}
            </div>
          </div>
        </div>
      </FilterBarContextProvider>
    </TaskContextProvider>
  );
}

export default Home;
