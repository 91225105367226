import React, { createContext, useState } from "react";

export const ModalContext = createContext(null);

const ModalContextProvider = ({ children }) => {
  const [formType, setFormType] = useState(null);
  const [showCreateEditModal, setShowCreateEditModal] = useState(false);
  const [showGeneralModal, setShowGeneralModal] = useState(false);
  const [generalModalType, setGeneralModalType] = useState(null);
  const [titleEmptyValidator, setTitleEmptyValidator] = useState(false);
  const [taskToDoEmptyValidator, setTaskToDoEmptyValidator] = useState(false);
  const [emptyTaskValidator, setEmptyTaskValidator] = useState(false);

  const handleModalPopUp = (newFormType) => {
    setShowCreateEditModal(true);
    setFormType(newFormType);
    setTitleEmptyValidator(false);
    setTaskToDoEmptyValidator(false);
    setEmptyTaskValidator(false);
  };

  const handleGeneralModal = (status) => {
    setGeneralModalType(status);
    setShowGeneralModal(true);
  };

  const value = {
    formType,
    showCreateEditModal,
    showGeneralModal,
    generalModalType,
    titleEmptyValidator,
    taskToDoEmptyValidator,
    emptyTaskValidator,
    setFormType,
    handleModalPopUp,
    setShowCreateEditModal,
    setShowGeneralModal,
    handleGeneralModal,
    setGeneralModalType,
    setTitleEmptyValidator,
    setTaskToDoEmptyValidator,
    setEmptyTaskValidator,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export default ModalContextProvider;
