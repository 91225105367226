import React, { useContext, useState } from "react";
import { asset } from "../../assets/assets";
import { MobileViewContext } from "../../context/MobileViewContext";
import { ModalContext } from "../../context/ModalContext";
import { TaskContext } from "../../context/TaskContext";
import { v4 as uuidv4 } from "uuid";
import { listDropdown, priorityDropdown } from "../../constant/constant";

function CreateEditTaskModal() {
  const { isMobileView } = useContext(MobileViewContext);
  const { setShowCreateEditModal } = useContext(ModalContext);

  const handleModalClose = (e) => {
    if (e.target === e.currentTarget) {
      setShowCreateEditModal(false);
    }
  };

  return (
    <div
      className={`${
        !isMobileView &&
        "bg-modalBackgroundOverlay flex justify-center items-center"
      } absolute top-0 left-0 z-30 h-screen w-screen `}
      onClick={handleModalClose}
    >
      <Form />
    </div>
  );
}

const Form = () => {
  const { formType, titleEmptyValidator } = useContext(ModalContext);
  const { selectedTask } = useContext(TaskContext);
  const [title, setTitle] = useState(
    formType === "Create" ? "" : selectedTask[0].title
  );
  const today = new Date();
  const offset = today.getTimezoneOffset() * 60000;
  const localDate = new Date(today.getTime() - offset);
  const todayFormatedDate = localDate.toISOString().split("T")[0];

  const [startTime, setStartTime] = useState(
    formType === "Create" ? todayFormatedDate : selectedTask[0].startTime
  );
  const [endTime, setEndTime] = useState(
    formType === "Create" ? todayFormatedDate : selectedTask[0].endTime
  );
  const [taskToDo, setTaskToDo] = useState(
    formType === "Create" ? [] : selectedTask[0].taskToDo
  );
  const [taskToDoCopy, setTaskToDoCopy] = useState(
    taskToDo.sort((a, b) => b.completed - a.completed)
  );

  const [priority, setPriority] = useState(
    formType === "Create" ? "high" : selectedTask[0].priority
  );
  const [listAssigned, setListAssigned] = useState(
    formType === "Create" ? "work" : selectedTask[0].listAssigned
  );

  return (
    <form className="h-full w-full md:h-[90vh] md:w-[600px] md:rounded-[15px] bg-white p-6 flex flex-col justify-between">
      <div>
        <h3 className="text-smHeader font-bold mb-4">
          <span>{formType === "Create" ? "Create New" : "Edit"} Task</span>
        </h3>

        <div className="mb-4 relative">
          <label className="text-smNormal" htmlFor="title">
            Title
          </label>
          <br />
          <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="bg-inputBackground rounded-[10px] py-2 px-3  w-full outline-none "
          />
          {titleEmptyValidator && (
            <div className="absolute">
              <p className="ml-1 text-red-600 text-[10px]">
                Oops, you need to have a title
              </p>
            </div>
          )}
        </div>

        <div className="mb-4">
          <label className="text-smNormal" htmlFor="startDate">
            Time Period
          </label>
          <br />
          <div className="flex justify-between items-center gap-1.5">
            <input
              type="date"
              id="startDate"
              name="startDate"
              min={todayFormatedDate}
              value={startTime}
              onChange={(e) => {
                setStartTime(e.target.value);
                setEndTime(e.target.value);
              }}
              className="bg-inputBackground rounded-[10px] py-2 px-3 w-full outline-none "
            />
            <p>-</p>
            <input
              type="date"
              id="endDate"
              name="endDate"
              min={startTime}
              value={endTime}
              onChange={(e) => {
                setEndTime(e.target.value);
              }}
              className="bg-inputBackground rounded-[10px] py-2 px-3 w-full outline-none "
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <TaskToDo
            taskToDo={taskToDo}
            setTaskToDo={setTaskToDo}
            taskToDoCopy={taskToDoCopy}
            setTaskToDoCopy={setTaskToDoCopy}
          />

          <div className="grid grid-cols-1 gap-4">
            <div>
              <label className="text-smNormal" htmlFor="priority">
                Priority
              </label>
              <select
                name="priority"
                id="priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                className="bg-inputBackground rounded-[10px] py-2 px-3 w-full outline-none capitalize"
              >
                {priorityDropdown.map((item) => {
                  return (
                    <option
                      className="capitalize"
                      key={item.name}
                      value={item.value}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label className="text-smNormal" htmlFor="keepInList">
                Keep In List
              </label>
              <select
                name="keepInList"
                id="keepInList"
                value={listAssigned}
                onChange={(e) => setListAssigned(e.target.value)}
                className="bg-inputBackground rounded-[10px] py-2 px-3 w-full outline-none capitalize"
              >
                {listDropdown.map((item) => {
                  return (
                    <option
                      key={item.name}
                      className="capitalize"
                      value={item.value}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>

      <FormButton
        title={title}
        startTime={startTime}
        endTime={endTime}
        taskToDoCopy={taskToDoCopy}
        priority={priority}
        listAssigned={listAssigned}
      />
    </form>
  );
};

const TaskToDo = ({ taskToDoCopy, setTaskToDoCopy }) => {
  const {
    taskToDoEmptyValidator,
    setTaskToDoEmptyValidator,
    emptyTaskValidator,
    setEmptyTaskValidator,
  } = useContext(ModalContext);
  const [inputTask, setInputTask] = useState("");

  const addNewTaskToDo = (e) => {
    e.preventDefault();

    if (!inputTask) {
      setTaskToDoEmptyValidator(false);
      setEmptyTaskValidator(true);
      return;
    } else {
      setTaskToDoEmptyValidator(false);
      setEmptyTaskValidator(false);
    }

    const toBeAdded = { id: uuidv4(), taskName: inputTask, completed: false };
    const newList = [...taskToDoCopy, toBeAdded];
    setTaskToDoCopy(newList);
    setInputTask("");
  };

  const handleChecked = (taskId) => {
    const updatedTasks = taskToDoCopy.map((item) =>
      item.id === taskId ? { ...item, completed: !item.completed } : item
    );
    setTaskToDoCopy(updatedTasks);
  };

  const deleteTaskToDo = (taskId) => {
    const deletedTask = taskToDoCopy.filter((item) => item.id !== taskId);
    setTaskToDoCopy(deletedTask);
  };

  return (
    <div className="relative">
      <label className="text-smNormal" htmlFor="taskToDo">
        Task to do
      </label>
      <br />
      <div>
        {taskToDoCopy.map((item) => {
          return (
            <div className="flex items-center gap-2 mb-2" key={item.id}>
              <div
                className="min-w-5 min-h-5 w-5 h-5 border-2 rounded-full flex items-center justify-center cursor-pointer"
                onClick={() => handleChecked(item.id)}
              >
                {item.completed && (
                  <div className="w-5 h-5 min-w-5 min-h-5 bg-green-300 rounded-full">
                    <img src={asset.checkedIcon} alt="" />
                  </div>
                )}
              </div>

              <div className="flex justify-between items-center w-full">
                <label
                  className={`${
                    item.completed && "line-through"
                  } cursor-pointer`}
                  htmlFor={item.taskName}
                  onClick={() => handleChecked(item.id)}
                >
                  {item.taskName}
                </label>
                <div className="min-w-5 min-h-5 w-5 h-5 cursor-pointer">
                  <img
                    src={asset.deleteTaskIcon}
                    alt=""
                    onClick={() => deleteTaskToDo(item.id)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-between gap-4">
        <input
          className="border-b p-1 outline-none w-full"
          type="text"
          placeholder="Add new task"
          value={inputTask}
          onChange={(e) => setInputTask(e.target.value)}
          maxLength={20}
        />
        <button
          className="min-w-5 bg-ctaButton w-5 h-5 rounded-full cursor-pointer"
          onClick={addNewTaskToDo}
        >
          <img src={asset.addIcon} alt="" />
        </button>
      </div>
      <div className="absolute">
        {taskToDoEmptyValidator && (
          <p className="ml-1 text-red-600 text-[10px]">
            Oops, you need to add at least a task
          </p>
        )}
        {emptyTaskValidator && (
          <p className="ml-1 text-red-600 text-[10px]">
            Oh no, you forgot to enter the name
          </p>
        )}
      </div>
    </div>
  );
};

const FormButton = ({
  title,
  startTime,
  endTime,
  taskToDoCopy,
  priority,
  listAssigned,
}) => {
  const {
    formType,
    setShowCreateEditModal,
    setTitleEmptyValidator,
    setTaskToDoEmptyValidator,
    setEmptyTaskValidator,
  } = useContext(ModalContext);
  const { task, setTask, selectedTask } = useContext(TaskContext);
  const id = selectedTask[0]?.id;

  const saveEdit = () => {
    if (!title) {
      setTitleEmptyValidator(true);
      return;
    } else {
      setTitleEmptyValidator(false);
    }

    if (!taskToDoCopy.length) {
      setTaskToDoEmptyValidator(true);
      return;
    } else {
      setTaskToDoEmptyValidator(false);
    }
    const today = new Date();
    const todayFormattedDate = today.toISOString().split("T")[0];
    const updatedTasks = task.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          title: title,
          startTime: startTime,
          endTime: endTime,
          taskToDo: taskToDoCopy,
          priority: priority,
          listAssigned: listAssigned,
          status: taskToDoCopy.every((item) => item.completed === true)
            ? "completed"
            : "in progress",
          updatedDate: todayFormattedDate,
        };
      }
      return item;
    });
    setTask(updatedTasks);
    setShowCreateEditModal(false);
  };

  const createTask = () => {
    if (!title) {
      setTitleEmptyValidator(true);
      return;
    } else {
      setTitleEmptyValidator(false);
    }

    if (!taskToDoCopy.length) {
      setEmptyTaskValidator(false);
      setTaskToDoEmptyValidator(true);
      return;
    } else {
      setEmptyTaskValidator(false);
      setTaskToDoEmptyValidator(false);
    }

    const today = new Date();
    const todayFormattedDate = today.toISOString().split("T")[0];
    const newTask = {
      id: uuidv4(),
      title: title,
      startTime: startTime,
      endTime: endTime,
      taskToDo: taskToDoCopy,
      priority: priority,
      listAssigned: listAssigned,
      status: taskToDoCopy.every((item) => item.completed === true)
        ? "completed"
        : "in progress",
      createdDate: todayFormattedDate,
      updatedDate: todayFormattedDate,
    };
    setTask([...task, newTask]);
    setShowCreateEditModal(false);
  };

  return (
    <div className="pt-4 border-t-2 border-divider flex justify-end gap-4">
      <button
        className="px-4 py-2 border-ctaBorder hover:border-ctaBorderHover hover:bg-progressBarWhiteHover border-2 text-smNormal rounded-[10px]"
        onClick={() => setShowCreateEditModal(false)}
      >
        Cancel
      </button>
      <button
        type="submit"
        className="px-4 py-2 text-smNormal bg-ctaButton text-white border-ctaBorder hover:bg-ctaButtonHover hover:border-ctaBorderHover border-2 rounded-[10px]"
        onClick={(e) => {
          e.preventDefault();
          return formType === "Create" ? createTask() : saveEdit();
        }}
      >
        {formType === "Create" ? "Create Task" : "Save Changes"}
      </button>
    </div>
  );
};

export default CreateEditTaskModal;
