import React, { useContext, useState, useEffect, useRef } from "react";
import { asset } from "../../assets/assets";
import DateTime from "../General/DateTime";
import { FilterBarContext } from "../../context/FilterBarContext";
import { listItem } from "../../constant/constant";

function MobileNavBar() {
  const { listFilter } = useContext(FilterBarContext);
  const [isListDropdownOpen, setIsListDropdownOpen] = useState(false);
  const dropdownButton = useRef(null);
  const dropdownList = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownButton.current &&
        !dropdownButton.current.contains(event.target) &&
        dropdownList.current &&
        !dropdownList.current.contains(event.target)
      ) {
        setIsListDropdownOpen(false);
      }
    }

    if (isListDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isListDropdownOpen]);

  return (
    <div className="flex items-center justify-between relative gap-2">
      <div
        ref={dropdownButton}
        className="bg-white hover:bg-progressBarWhiteHover py-2 px-4 lg:py-3 lg:px-6 rounded-full cursor-pointer w-[150px] flex justify-between"
        onClick={() => setIsListDropdownOpen(!isListDropdownOpen)}
      >
        <div className="flex items-center gap-2">
          <img className="w-4" src={asset[`${listFilter}Icon`]} alt="" />
          <p className="capitalize">{listFilter}</p>
        </div>
        <img className="w-3" src={asset.arrowDownIcon} alt="" />
      </div>

      {isListDropdownOpen && (
        <ListDropdown
          setIsListDropdownOpen={setIsListDropdownOpen}
          dropdownRef={dropdownList}
        />
      )}
      <DateTime />
    </div>
  );
}

const ListDropdown = ({ setIsListDropdownOpen, dropdownRef }) => {
  const { listFilter, setListFilter } = useContext(FilterBarContext);

  return (
    <div
      ref={dropdownRef}
      className="absolute top-12 bg-white shadow-lg left-0 z-30 rounded-[15px] w-[150px]"
    >
      {listItem.map((item) => {
        return (
          <div
            key={`mobile_list_dropdown_${item.name}`}
            className={`${
              listFilter === item.name && "bg-progressBarWhiteHover"
            } py-2 px-4 cursor-pointer hover:bg-hoverColor first:rounded-t-[15px] last:rounded-b-[15px] border-b last:border-none flex items-center gap-2`}
            onClick={() => {
              setListFilter(item.name);
              setIsListDropdownOpen(false);
            }}
          >
            <img className="w-4" src={item.icon} alt="" />
            <p className="capitalize">{item.name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default MobileNavBar;
